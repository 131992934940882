import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment';
import currency from 'currency.js';
import { ParseIntAndFormat } from '../ParseIntAndFormat';
const generatePDF =(collection)=> {
    const doc= new jsPDF();

    const tableColumns = ['Name','Username','W/L'];
    const tableRows = [];
    let itemsData=[];
    const lastIndex=collection.length-1;
  
    let totalWinLose = currency(0);
    collection.forEach((element,index) => {
        const winLose = currency(element.smasterBetAmount).subtract(currency(element.smasterCommissionAmount).add(element.smasterWinAmount));
            tableRows.push([element.name,element.smasterName,ParseIntAndFormat(winLose)])
            totalWinLose=totalWinLose.add(winLose);
    });

    tableRows.push(['','Total',ParseIntAndFormat(totalWinLose.value)]);
    const time = moment().locale('my').format('DD/MM/YYYY hh:mm a');
    doc.text(`Senior Master Report ${time}`,15,10)
    
    doc.autoTable(tableColumns, tableRows, {
        theme: 'grid',
        headerStyles: { halign: 'right' },
        margin: { top: 20 },
        columnStyles: { 
              0: { halign: 'right' }, 
              1: { halign: 'right' } ,
              2: { halign: 'right' } ,
        }
  });
  const dateStr=moment().locale('my').format('DD-MM-YY__hh__mm__a');
  doc.save(`SMaster Report.pdf`);

}
export default generatePDF;

