export const getSelectedKey = ({ pathname }) => {
      if (pathname.includes("/senior_masters/") && /\d/.test(pathname)) {
            return ['13'];
      }
      if (pathname.includes("/threeDCollections/") && /\d/.test(pathname)) {
            return ['7'];
      }
      if (pathname.includes("/twoDCollections/") && /\d/.test(pathname)) {
            return ['8'];
      }
      if (pathname.includes("/bet_detail/") && /\d/.test(pathname)) {
            return ['17'];
      }
      if (pathname.includes("/2d_detail/") && /\d/.test(pathname)) {
            return ['17'];
      }
      if (pathname.includes("/3d_detail/") && /\d/.test(pathname)) {
            return ['17'];
      }
      if (pathname.includes("/fourDCollections") && /\d/.test(pathname)) {
            return ['97'];
      }
      if (pathname.includes("/4d_detail/") && /\d/.test(pathname)) {
            return ['97'];
      }
      if (pathname.includes("/fourDResults/") && /\d/.test(pathname)) {
            return ['97'];
      }
      if(pathname.includes("threed-bets")){
            return ['7'];
      }
      switch (pathname) {
            case "/soccer_collections":
                  return ['1'];
            case "/lsport":
                  return ['2'];
            case "/leagues":
                  return ['3'];
            case "/teams":
                  return ['4'];
            case "/announcement":
                  return ['5'];
            case "/rules":
                  return ['6'];
            case "/twoDCollections":
                  return ['8'];
            case "/winPercent":
                  return ['9'];
            case "/senior_masters/new":
                  return ['10'];
            case "/twothreeLimits":
                  return ['11'];
            case "/partner23commissions":
                  return ['12'];
            case "/senior_masters":
                  return ['13'];
            case "/priceGaps":
                  return ['14'];
            case "/3DWinPercent":
                  return ['15'];
            case "/threeDCollections":
                  return ['7'];
            case "/collection_report":
                  return ['16'];
            case "/":
                  return ['17'];
            case "/smaster_report":
                  return ['18'];
            case "/patternByBetMatchCount":
                  return ['17'];
            case "/pattern_detail":
                  return ['17'];
            case "/betsByPattern":
                  return ['17'];
            case "/agreements":
                  return ['24'];
            case "/payments":
                  return ['99'];
            case "/report/match-count":
                  return ['98'];
            case "/fourDCollections":
                  return ['97'];
            case "/winMessages":
                  return ['96'];
            case "/loseMessages":
                  return ['95'];
            case "/employees/signup":
                  return ['94'];
            case "/employees":
                  return ['93'];
            case "/delete/3months-data":
                  return ['92'];
            default:
                  return ['1'];
      }
}