import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import {getMessage} from '../../utilities/getMessage';

export const createMatchStart=()=>{
    return {
        type:actionTypes.CREATE_MATCHES_START
    }
}

export const createMatchSuccess=(data)=>{
    return {
        type:actionTypes.CREATE_MATCHES_SUCCESS,
        payload:data
    }
}

export const createMatchFail=(error)=>{
    return {
        type:actionTypes.CREATE_MATCHES_FAIL,
        payload:getMessage(error)
    }
}

export const createMatch=(collection,token)=>async dispatch=>{
   dispatch(createMatchStart());
   const url = `${api}/matches/create`;
   const options = {
      url: url,
      method: 'POST',
      headers: {
         'Authorization': 'Bearer ' + token
      },
      data: collection
   };
   await axios(options)
      .then(response => {
         dispatch(createMatchSuccess(response.data));
         console.log('response', response);
      })
      .catch(error => dispatch(createMatchFail(error)));
}