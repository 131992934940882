import React, { useEffect, useState } from 'react';
import { Row, Col, Table, message, Pagination } from 'antd';
import {
    EyeOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory,useLocation } from 'react-router-dom';
import { getBetsByPattern } from '../../redux/actions/betPattern/getBetsByPattern';
import currency from 'currency.js';
import { updatePagination } from '../../redux/actions/betPattern/updatePagination';

const BetsByPattern = ({
    loading,
    error,
    token,
    onGet,
    bets,
    oldPattern,
    updatePagination,
    currentPage,
    pageSize
}) => {
    const history = useHistory();
    const location = useLocation();
    const pattern=location?.state?.pattern;
    useEffect(async () => {
        if(!bets || oldPattern !== pattern){
            await onGet(pattern,token);
        }
        if (error) {
            message.error(error);
        }
    }, [pattern])

    const { Column, ColumnGroup } = Table;

    const [tableData, setTableData] = useState([]); 
  
    const getTableData = (data, currentPage, pageSize) => {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      return data.slice(startIndex, endIndex);
    };
  
    useEffect(() => {
      const slicedData = getTableData(bets, currentPage, pageSize);
      setTableData(slicedData);
    }, [currentPage, pageSize, bets]);

    return <>
        <Row gutter={[24,24]} justify="left">
            <Col lg={24} md={24} sm={24}>
                <Table
                    pagination={false}
                    bordered
                    dataSource={tableData}
                    loading={loading}
                >
                    <Column
                        title="Bet Id"
                        dataIndex="id"
                        key="id"
                        align="center"
                    />
                    <Column
                        title="Username"
                        dataIndex="username"
                        key="username"
                    />
                    <ColumnGroup title="Partner">
                        <Column
                            title="Bet"
                            dataIndex="partnerBetAmount"
                            key="partnerBetAmount"
                            align="right"
                        />
                        <Column
                            title="Commission"
                            dataIndex="partnerCommissionAmount"
                            key="partnerCommissionAmount"
                            align="right"
                        />
                        <Column
                            title="W/L"
                            render={(text, {
                                partnerBetAmount,
                                partnerWinAmount,
                                partnerCommissionAmount }) =>
                               currency(partnerBetAmount).subtract(currency(partnerWinAmount).add(currency(partnerCommissionAmount))).value
                            }
                        />
                    </ColumnGroup>

                    <ColumnGroup title="Senior Master">
                        <Column
                            title="Bet"
                            dataIndex="smasterBetAmount"
                            key="smasterBetAmount"
                            align="right"
                        />
                        <Column
                            title="Commission"
                            dataIndex="smasterCommissionAmount"
                            key="smasterCommissionAmount"
                            align="right"
                        />
                        <Column
                            title="W/L"
                            render={(text, {
                                smasterBetAmount,
                                smasterWinAmount,
                                smasterCommissionAmount }) =>
                                currency(smasterBetAmount).subtract(currency(smasterWinAmount).add(smasterCommissionAmount))
                            }
                        />
                    </ColumnGroup>
                    <Column
                        title="Detail"
                        key="detail"
                        render={(text, record) => <EyeOutlined
                            onClick={() => {
                                history.push(`/bet_detail/${record.id}`)
                            }}
                        />}
                    />
                </Table>
            </Col>
        </Row>
        { bets && <div style={{ marginTop:24, width:'fit-content', marginLeft:'auto'}}>
                <Pagination pageSize={pageSize} current={currentPage} onChange={updatePagination} total={bets.length}/>
            </div>}
    </>
}

const mapPropsToState = state => ({
    token: state.auth.token,
    error: state.betPattern.error,
    loading: state.betPattern.loading,
    bets: state.betPattern.betsByPattern,
    oldPattern:state.betPattern.pattern,
    currentPage:state.betPattern.currentPage,
    pageSize:state.betPattern.pageSize,
})

const mapDispatchToProps = dispatch => ({
    onGet: (pattern,token) => dispatch(getBetsByPattern(pattern,token)),
    updatePagination: (currentPage,pageSize) => dispatch(updatePagination(currentPage,pageSize))
})

export default connect(mapPropsToState, mapDispatchToProps)(BetsByPattern);