import React, { useEffect } from 'react';
import { Row, Col, Table, Spin } from 'antd';
import {
    EyeOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import currency from 'currency.js';
import { getCheatedBets } from '../../redux/actions/betPattern/getCheatedBets';
import { useParams } from 'react-router-dom';

const CheatedBets = ({
    loading,
    token,
    onGet,
    bets,
}) => {
    const history = useHistory();
    const { betId } = useParams();
    useEffect(async () => {
        betId && onGet(betId, token)
    }, [betId])

    const { Column, ColumnGroup } = Table;

    return loading ? <Spin /> : <>
        <Row gutter={[24, 24]} justify="left">
            <Col lg={24} md={24} sm={24}>
                <h1>Cheated Bets</h1>
                <Table
                    bordered
                    dataSource={bets}
                    loading={loading}
                >
                    <Column
                        title="Bet Id"
                        dataIndex="id"
                        key="id"
                        align="center"
                    />
                    <Column
                        title="Username"
                        dataIndex="username"
                        key="username"
                    />
                    <ColumnGroup title="Partner">
                        <Column
                            title="Bet"
                            dataIndex="partnerBetAmount"
                            key="partnerBetAmount"
                            align="right"
                        />
                        <Column
                            title="Commission"
                            dataIndex="partnerCommissionAmount"
                            key="partnerCommissionAmount"
                            align="right"
                        />
                        <Column
                            title="W/L"
                            render={(text, {
                                partnerBetAmount,
                                partnerWinAmount,
                                partnerCommissionAmount }) =>
                                currency(partnerBetAmount).subtract(currency(partnerWinAmount).add(currency(partnerCommissionAmount))).value
                            }
                        />
                    </ColumnGroup>

                    <ColumnGroup title="Senior Master">
                        <Column
                            title="Bet"
                            dataIndex="smasterBetAmount"
                            key="smasterBetAmount"
                            align="right"
                        />
                        <Column
                            title="Commission"
                            dataIndex="smasterCommissionAmount"
                            key="smasterCommissionAmount"
                            align="right"
                        />
                        <Column
                            title="W/L"
                            render={(text, {
                                smasterBetAmount,
                                smasterWinAmount,
                                smasterCommissionAmount }) =>
                                currency(smasterBetAmount).subtract(currency(smasterWinAmount).add(smasterCommissionAmount))
                            }
                        />
                    </ColumnGroup>
                    <Column
                        title="Detail"
                        key="detail"
                        render={(text, record) => <EyeOutlined
                            onClick={() => {
                                history.push(`/bet_detail/${record.id}`)
                            }}
                        />}
                    />
                </Table>
            </Col>
        </Row>
    </>
}

const mapPropsToState = state => ({
    token: state.auth.token,
    error: state.betPattern.error,
    loading: state.betPattern.loading,
    bets: state.betPattern.cheatedBets,
})

const mapDispatchToProps = dispatch => ({
    onGet: (id, token) => dispatch(getCheatedBets(id, token)),
})

export default connect(mapPropsToState, mapDispatchToProps)(CheatedBets);